#home {
    display: flex;
    flex-direction:column;
}

#threejscanvas {
    flex: 1;
    background:pink;
}

#threejscanvas .column {
    padding: 0;
    margin: 0;
    line-height: 0;
}