@import "~materialize-css/sass/materialize.scss";

.image_header {
  margin-top: 2em;

  img {
    width: 100%;
  }

  h1 {
    text-align: justify;
    margin-top: 0;
    font-size: 2.5em;
    letter-spacing: 15px;
  }
}

#thing_clock {
  .row {
    margin-bottom: 30px;
  }

  .chip {
    margin-bottom: 0;
  }
}
