:root {
  --flip-speed: 0.55s;
  --rotate-speed: 0.5s;
  --rotate-distance: 360deg;

  --main-pulse-speed: 4s;

  --pulse-scale-minimum: 1;
  --pulse-scale-max: 1;

  --distance-color-1: 23, 195, 13;
  --distance-color-2: 41, 202, 32;
  --distance-color-3: 50, 206, 42;
  --distance-color-4: 73, 216, 67;
  --distance-color-5: 99, 222, 96;
  --distance-color-6: 111, 223, 115;
  --distance-color-7: 122, 224, 132;
  --distance-color-8: 135, 226, 151;
  --distance-color-9: 147, 227, 170;
  --distance-color-10: 161, 228, 192;
  --distance-color-11: 181, 230, 218;
  --distance-color-12: 192, 240, 238;

  --box-shadow-size: 14px;

  --icon-border-size: 4px;

  /* Inner values */

  --inner-pulse-scale-maximum: 0.2;
  --inner-pulse-scale-minimum: 1.2;
  --inner-pulse-color: 214, 73, 30;
  --inner-box-shadow-size: 19px;
  --secondary-pulse-speed: 4s;

  --icon-width-height: 4.2rem;
}

@media screen and (max-width: 450px) {
  :root {
    --icon-width-height: 3.2rem;
  }
}

.display {
  padding: 1rem;
  position: relative;
  height: calc(var(--icon-width-height) * 8);
}

.indicator {
  border-radius: 50%;
  width: var(--icon-width-height);
  height: var(--icon-width-height);
  position: absolute;
  top: 0px;
  left: calc(50% - calc(var(--icon-width-height) / 2));
  transform-origin: calc(var(--icon-width-height) / 2)
    calc(var(--icon-width-height) * 3.5);
}

.indicator img {
  border-radius: 50%;
  width: var(--icon-width-height);
  height: var(--icon-width-height);
  transform: scale(1);
  transition: filter var(--rotate-speed);
}

.display > li:nth-child(0) {
  transform: rotate(0);
}

.display > li:nth-child(0) > div {
  transform: rotate(0);
}

.display > li:nth-child(1) {
  transform: rotate(30deg);
}

.display > li:nth-child(1) > div {
  transform: rotate(-30deg);
}

.display > li:nth-child(2) {
  transform: rotate(60deg);
}

.display > li:nth-child(2) > div {
  transform: rotate(-60deg);
}

.display > li:nth-child(3) {
  transform: rotate(90deg);
}

.display > li:nth-child(3) > div {
  transform: rotate(-90deg);
}

.display > li:nth-child(4) {
  transform: rotate(120deg);
}

.display > li:nth-child(4) > div {
  transform: rotate(-120deg);
}

.display > li:nth-child(5) {
  transform: rotate(150deg);
}

.display > li:nth-child(5) > div {
  transform: rotate(-150deg);
}

.display > li:nth-child(6) {
  transform: rotate(180deg);
}

.display > li:nth-child(6) > div {
  transform: rotate(-180deg);
}

.display > li:nth-child(7) {
  transform: rotate(210deg);
}

.display > li:nth-child(7) > div {
  transform: rotate(-210deg);
}

.display > li:nth-child(8) {
  transform: rotate(240deg);
}

.display > li:nth-child(8) > div {
  transform: rotate(-240deg);
}

.display > li:nth-child(9) {
  transform: rotate(270deg);
}

.display > li:nth-child(9) > div {
  transform: rotate(-270deg);
}

.display > li:nth-child(10) {
  transform: rotate(300deg);
}

.display > li:nth-child(10) > div {
  transform: rotate(-300deg);
}

.display > li:nth-child(11) {
  transform: rotate(330deg);
}

.display > li:nth-child(11) > div {
  transform: rotate(-330deg);
}

.gone img {
  filter: grayscale(100%) brightness(50%);
  animation: rotate-center var(--rotate-speed) ease-in-out both;
}

/* 
@media (prefers-reduced-motion: reduce) {
  .indicator {
    animation: none;
  }
} */

.empty {
  border: 1px solid rgb(145, 145, 145);
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.05926120448179273) 0%,
    rgba(164, 181, 185, 0.465983893557423) 96%
  );
}

/* .there {
  animation: pulse-inner calc(var(--secondary-pulse-speed) * 1) infinite;
} */

.distance-12 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-12), 1);
  border: 3px solid rbga(var(--distance-color-12));
  animation: pulse-distance-12 calc(var(--main-pulse-speed) * 1) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-11 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-11), 1);
  animation: pulse-distance-11 calc(var(--main-pulse-speed) * 1) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-10 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-10), 1);
  animation: pulse-distance-10 calc(var(--main-pulse-speed) * 1) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-9 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-9), 1);
  animation: pulse-distance-9 calc(var(--main-pulse-speed) * 1) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-8 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-8), 1);
  animation: pulse-distance-8 calc(var(--main-pulse-speed) * 1) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-7 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-7), 1);
  animation: pulse-distance-7 calc(var(--main-pulse-speed) * 1) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-6 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-6), 1);
  animation: pulse-distance-6 calc(var(--main-pulse-speed) * 0.9) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-5 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-5), 1);
  animation: pulse-distance-5 calc(var(--main-pulse-speed) * 0.9) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-4 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-4), 1);
  animation: pulse-distance-4 calc(var(--main-pulse-speed) * 0.9) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-3 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-3), 1);
  animation: pulse-distance-3 calc(var(--main-pulse-speed) * 0.75) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-2 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-2), 1);
  animation: pulse-distance-2 calc(var(--main-pulse-speed) * 0.6) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

.distance-1 img {
  border: var(--icon-border-size) solid rgba(var(--distance-color-1), 1);
  animation: pulse-distance-1 calc(var(--main-pulse-speed) * 0.4) infinite,
    rotate-center-back var(--rotate-speed) ease-in-out both;
}

@keyframes pulse-distance-12 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-12), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1)
      rgba(var(--distance-color-12), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-12), 0);
  }
}

@keyframes pulse-distance-11 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-11), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1)
      rgba(var(--distance-color-11), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-11), 0);
  }
}

@keyframes pulse-distance-10 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-10), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1)
      rgba(var(--distance-color-10), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-10), 0);
  }
}

@keyframes pulse-distance-9 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-9), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1)
      rgba(var(--distance-color-9), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-9), 0);
  }
}

@keyframes pulse-distance-8 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-8), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1)
      rgba(var(--distance-color-8), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-8), 0);
  }
}

@keyframes pulse-distance-7 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-7), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1)
      rgba(var(--distance-color-7), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-7), 0);
  }
}

@keyframes pulse-distance-6 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-6), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1)
      rgba(var(--distance-color-6), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-6), 0);
  }
}

@keyframes pulse-distance-5 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-5), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1.1)
      rgba(var(--distance-color-5), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-5), 0);
  }
}

@keyframes pulse-distance-4 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-4), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1.1)
      rgba(var(--distance-color-4), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-4), 0);
  }
}

@keyframes pulse-distance-3 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-3), 0.75);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1.1)
      rgba(var(--distance-color-3), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-3), 0);
  }
}

@keyframes pulse-distance-2 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-2), 0.75);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1.1)
      rgba(var(--distance-color-2), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-2), 0);
  }
}

@keyframes pulse-distance-1 {
  0% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-1), 0.7);
  }

  70% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1));
    box-shadow: 0 0 0 calc(var(--box-shadow-size) * 1.1)
      rgba(var(--distance-color-1), 0);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-minimum) * 1));
    box-shadow: 0 0 0 0 rgba(var(--distance-color-1), 0);
  }
}

@keyframes pulse-inner {
  0% {
    box-shadow: 0 0 0 calc(var(--inner-box-shadow-size) * 1.1)
      rgba(var(--inner-pulse-color), 0);
  }

  40% {
    box-shadow: 0 0 0 0 rgba(var(--inner-pulse-color), 0.2);
  }

  100% {
    box-shadow: 0 0 0 calc(var(--inner-box-shadow-size) * 1.1)
      rgba(var(--inner-pulse-color), 0);
  }
}

@keyframes flip-scale-up-ver {
  0% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1)) rotateY(0);
  }

  50% {
    transform: scale(1.5) rotateY(90deg);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1)) rotateY(180deg);
  }
}

@keyframes rotate-center-back {
  0% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1)) rotateY(180deg);
  }

  50% {
    transform: scale(1.5) rotateY(90deg);
  }

  100% {
    transform: scale(calc(var(--pulse-scale-maximum) * 1)) rotateY(0);
  }
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(var(--rotate-distance));
  }
}

@keyframes rotate-center-back {
  0% {
    transform: rotate(var(--rotate-distance));
  }
  100% {
    transform: rotate(0);
  }
}
